<template>
    <div>
        <r-e-dialog title="换房" :visible.sync="dialogVisible" show-footer top="5vh" width="600px"
                    :footerBtnName="['取消','下一步']" @click-submit="handleSubmit" @click-cancel="handleCancel"
                    @close="handleCancel">
            <el-steps :active="1" align-center>
                <el-step title="选择房源"/>
                <el-step title="核实费用"/>
            </el-steps>
            <el-form ref="formPublish1" label-width="120px" size="small">
                <div class="flex">
                    <el-form-item label="房源信息">
                        <el-input v-model="currentHouse.address" disabled/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="换房时间">
                        <el-date-picker v-model="huanFangData.changeDate" type="date" format="yyyy-MM-dd"
                                        style="width: 100%;"
                                        value-format="yyyy-MM-dd" placeholder="选择日期"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="新小区">
                        <el-select v-model="communityUuid" filterable placeholder="请输入关键词">
                            <el-option v-for="item in communityList" :key="item.uuid" :label="item.name"
                                       :value="item.uuid"/>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="新房号">
                        <el-select v-model="huanFangData.newApartmentUuid" filterable placeholder="请输入关键词">
                            <el-option v-for="item in options" :key="item.uuid" :label="item.name" :value="item.uuid"/>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="新房租金">
                        <el-input-number v-model="huanFangData.newApartmentRentalAmount" :precision="2" :min="0"
                                         controls-position="right" style="width: 100%" placeholder="请输入内容"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="新房服务费">
                        <el-input-number v-model="huanFangData.newApartmentServiceAmount" :precision="2" :min="0"
                                         controls-position="right" style="width: 100%" placeholder="请输入内容"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="新房押金">
                        <el-input-number v-model="huanFangData.newApartmentDepoAmount" :precision="2"
                                         controls-position="right"
                                         placeholder="请输入内容" :min="0" style="width: 100%"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <!--<el-form-item label="是否生成账单">
                        <el-radio-group v-model="formPublish.bill">
                            <el-radio-button :label="true">是</el-radio-button>
                            <el-radio-button :label="false">否</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否人脸认证">
                        <div style="width: 200px;">
                            <el-radio-group v-model="formPublish.face">
                                <el-radio-button :label="true">是</el-radio-button>
                                <el-radio-button :label="false">否</el-radio-button>
                            </el-radio-group>
                        </div>
                    </el-form-item>-->
                    <el-form-item label="是否是客户原因">
                        <el-radio-group v-model="huanFangData.reasonCode">
                            <el-radio-button :label="1">否</el-radio-button>
                            <el-radio-button :label="2">是</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>

        <r-e-dialog title="换房" :visible.sync="dialogVisibleXiang" show-footer top="3vh" width="1500px" fullscreen
                    :footerBtnName="['上一步','确定']" @click-submit="handleXiangSubmit" @click-cancel="previousStep"
                    @close="handleCancel">
            <el-steps :active="2" align-center>
                <el-step title="选择房源"/>
                <el-step title="核实费用"/>
            </el-steps>
            <div style="height: 70vh;">
                <div style="height: 100%;width: 100%;">
                    <div class="flex" style="height: 100%;flex-flow: column wrap;">
                        <div class="boder-right width33b" style="padding: 15px 0;">
                            <div class="title">旧房地址</div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E6E6E6">{{ huanFangData.apartmentName }}</div>
                        </div>
                        <div class="boder-right width33b" style="padding: 15px 0;">
                            <div class="title">租户信息</div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E6E6E6">{{ huanFangData.leasorName }}</div>
                        </div>
                        <div class="boder-right width33b padding_0_30 flex">
                            <div style="background-color: #F6F6F6;border-radius: 5px;color:#333333;padding: 10px 20px;">
                                <span>换房时间 </span>
                                <span>{{ huanFangData.changeDate }}</span>
                            </div>
                        </div>
                        <div class="boder-right width33b" style="padding: 15px 0;">
                            <div class="title">换房原因</div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <el-button :type="huanFangData.reasonCode===2?'primary':''"
                                       style="font-size: 14px;padding: 8px 15px;">客户原因
                            </el-button>
                            <el-button :type="huanFangData.reasonCode===1?'primary':''"
                                       style="font-size: 14px;padding: 8px 15px;">房间原因
                            </el-button>
                        </div>

                        <div class="boder-right width33b" style="padding: 15px 0;">
                            <div class="title">旧房退费</div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E2F5FF">
                                <div class="text-left width100">费用类型</div>
                                <div class="text-right width100">合计</div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row">
                                <div class="text-left width100">
                                    <el-link type="primary" @click="goBill('房租退费',huanFangData.quitRentalPayBill,true)">
                                        房租
                                    </el-link>
                                </div>
                                <div class="text-right width100">{{
                                        numberFormat(huanFangData.quitRentalAmountTotal)
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100">
                                    <el-link type="primary"
                                             @click="goBill('服务费退费',huanFangData.quitServicePayBill,true)">服务费
                                    </el-link>
                                </div>
                                <div class="text-right width100">{{
                                        numberFormat(huanFangData.quitServiceAmountTotal)
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="boder-right width33b" style="padding: 15px 0;">
                            <div class="title">旧房扣费</div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E2F5FF">
                                <div class="text-left width100">费用类型</div>
                                <div class="text-right width100">合计</div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row">
                                <div class="text-left width100">
                                    <el-link :underline="false"
                                             @click="goBill('房租扣费',huanFangData.payOldRentalPayBill)">房租
                                    </el-link>
                                </div>
                                <div class="text-right width100">{{
                                        numberFormat(huanFangData.payOldRentalAmountTotal)
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100">
                                    <el-link type="primary" @click="goBill('服务费扣费',huanFangData.payOldServicePayBill)">
                                        服务费
                                    </el-link>
                                </div>
                                <div class="text-right width100">{{
                                        numberFormat(huanFangData.payOldServiceAmountTotal)
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row">
                                <div class="text-left width100">
                                    <el-link type="primary" @click="goBill('水费扣费',huanFangData.payWaterPayBill)">水费
                                    </el-link>
                                </div>
                                <div class="text-right width100">{{ numberFormat(huanFangData.oldWtaterAmount) }}</div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100">
                                    <el-link type="primary" @click="goBill('电费扣费',huanFangData.payElectPayBill)">电费
                                    </el-link>
                                </div>
                                <div class="text-right width100">{{ numberFormat(huanFangData.oldElectAmount) }}</div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row">
                                <div class="text-left width100">清洁费</div>
                                <div class="text-right width100">
                                    <span v-if="!cleanShow" @click="amountClick('cleanShow','payCleanAmount')">
                                        {{ numberFormat(huanFangData.payCleanAmount) }}
                                    </span>
                                    <el-input v-else v-model="huanFangData.payCleanAmount" ref="payCleanAmount"
                                              size="mini"
                                              type="number" @blur="amountBlur('cleanShow','payCleanAmount')"
                                              placeholder="请输入内容"/>
                                </div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100">家具损坏费</div>
                                <div class="text-right width100">
                                    <span v-if="!damageShow" @click="amountClick('damageShow','payMaintianAmount')">
                                        {{ numberFormat(huanFangData.payMaintianAmount) }}
                                    </span>
                                    <el-input v-else v-model="huanFangData.payMaintianAmount" ref="payMaintianAmount"
                                              size="mini"
                                              type="number" @blur="amountBlur('damageShow','payMaintianAmount')"
                                              placeholder="请输入内容"/>
                                </div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row">
                                <div class="text-left width100">其他费用</div>
                                <div class="text-right width100">
                                    <span v-if="!otherShow" @click="amountClick('otherShow','payOtherAmount')">
                                        {{ numberFormat(huanFangData.payOtherAmount) }}
                                    </span>
                                    <el-input v-else v-model="huanFangData.payOtherAmount" ref="payOtherAmount"
                                              size="mini"
                                              type="number" @blur="amountBlur('otherShow','payOtherAmount')"
                                              placeholder="请输入内容"/>
                                </div>
                            </div>
                        </div>
                        <div v-if="huanFangData.reasonCode===2" class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100">换房手续费</div>
                                <div class="text-right width100">
                                    <span v-if="!payChangeShow" @click="amountClick('payChangeShow','payChangeAmount')">
                                        {{ numberFormat(huanFangData.payChangeAmount) }}
                                    </span>
                                    <el-input v-else v-model="huanFangData.payChangeAmount" ref="payChangeAmount"
                                              size="mini"
                                              type="number" @blur="amountBlur('payChangeShow','payChangeAmount')"
                                              placeholder="请输入内容"/>
                                </div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E6E6E6">
                                <div>旧房扣费总额（元）
                                    <span class="fw600"
                                          style="margin-left: 20px">{{ numberFormat(oldFangAmountTotal) }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E6E6E6">
                                <div>旧房扣费总额 = 房租 + 服务费 + 杂费</div>
                            </div>
                        </div>
                        <div class="boder-right width33b" style="padding: 15px 0;">
                            <div class="title">新房地址</div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E6E6E6">{{ huanFangData.newApartmentName }}</div>
                        </div>
                        <div class="boder-right width33b" style="padding: 15px 0;">
                            <div class="title">新房费用</div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E2F5FF">
                                <div class="text-left width100">费用类型</div>
                                <div class="text-right width100">合计</div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row">
                                <div class="text-left width100">押金</div>
                                <div class="text-right width100">{{ numberFormat(huanFangData.payDepoAmount) }}</div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100">房租</div>
                                <div class="text-right width100">{{ numberFormat(huanFangData.payRentalAmount) }}</div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-F8F8F8">
                                <div class="text-left width100">服务费</div>
                                <div class="text-right width100">{{ numberFormat(huanFangData.payServiceAmount) }}</div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E6E6E6">
                                <div>新房费用总额（元）
                                    <span class="fw600"
                                          style="margin-left: 20px">{{ numberFormat(newFangAmountTotal) }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="boder-right width33b padding_0_30">
                            <div class="flex justify-between row bg-E6E6E6">
                                <div>新房费用总额 = 房租 + 服务费 + 押金</div>
                            </div>
                        </div>
                        <div class="flex align-baseline width33b padding_10_30 fw600">
                            <div style="width: 50px;">总计:</div>
                            <div>
                                <div style="font-size: 24px;">{{ numberFormat(huanFangData.totalAmount) }}</div>
                                <div v-if="huanFangData.totalAmount<0" style="font-size: 12px;color:#FF6740;">
                                    退还给租客{{ numberFormat(-huanFangData.totalAmount) }}
                                </div>
                                <div v-else style="font-size: 12px;color: #FF6740;">
                                    向租客收取{{ numberFormat(huanFangData.totalAmount) }}
                                </div>
                            </div>
                        </div>
                        <div class="flex align-baseline width33b padding_10_30 fw600">
                            <div>总计 = 房租退款({{ numberFormat(huanFangData.rentalDrawback) }}) +
                                杂费退款({{ numberFormat(huanFangData.expensesDrawback) }}) +
                                房租支付({{ numberFormat(huanFangData.rentalPay) }}) +
                                杂费付款({{ numberFormat(huanFangData.expensesPay) }}) +
                                押金付款({{ numberFormat(huanFangData.depoPay) }})
                            </div>
                        </div>
                        <div style="width: 25%;padding: 10px 30px">
                            <div style="font-weight: 600;margin-bottom: 10px">上传图片</div>
                            <div style="padding-left: 20px;">
                                <upload-picture-card :uuidList="imgList" :limit="24" @on-success="handleSuccess"
                                                     @on-remove="handleRemove"/>
                            </div>
                        </div>
                        <div class="width33b padding_10_30">
                            <div style="font-weight: 600;padding-bottom: 10px">备注</div>
                            <el-input type="textarea" v-model="huanFangData.comment" :rows="3" style="width: 100%;"
                                      placeholder="请输入内容"/>
                        </div>
                    </div>
                </div>
            </div>
            <r-e-dialog :title="billTitle" :visible.sync="billSwitch" show-footer top="5vh" width="1000px"
                        :showFooter="false"
                        @click-cancel="billListClose" v-dialog-drag>
                <r-e-table ref="tableRef" :data="billList" :height="500" :showPagination="false"
                           show-summary :summary-method="getSummaries">
                    <el-table-column label="账单时间">
                        <template slot-scope="{ row }">{{ timeFormat(row.startDate) + "-" + timeFormat(row.endDate) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="账单金额" prop="amount">
                        <template slot-scope="{ row }">{{ numberFormat(row.amount / 100) }}</template>
                    </el-table-column>
                    <el-table-column v-if="billType" label="退费金额" prop="checkOutAmount">
                        <template slot-scope="{ row }">{{ numberFormat(row.checkOutAmount / 100) }}</template>
                    </el-table-column>
                </r-e-table>
            </r-e-dialog>
        </r-e-dialog>
    </div>
</template>

<script>
import {
    getApartmentData, getPenewalChangeDetails, renewalByName, renewalRoomChangeSave, setApartmentData
} from "@/api/house-resource";
import {numberFormat, ParseIntFormat, timeFormat} from "@custom/index";
import {MessageError, MessageInfo, MessageSuccess} from "@custom/message";
import {getCommunityListApi} from "@/api/community-management";
import {getContracTimeApiworitr} from "@/api/contract";

export default {
    name: "dialog-exchange-houses",
    props: {
        currentHouse: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            dialogVisible: false,
            dialogVisibleXiang: false,
            options: [],
            contractsData: {},
            huanFangData: {
                changeDate: null,//换房时间
                reasonCode: 1,  //换房原因code 1房间 2客户
                reasonName: "房间", //换房原因name 1房间 2客户
                comment: null,  //备注
                workPictures: null, //	照片
                expenseTotalAmount: null,//杂费总合
                leasorUuid: null,//租户uuid
                newApartmentDepoAmount: null,//新房间押金
                newApartmentRentalAmount: null,//新房间房租
                newApartmentServiceAmount: null,//新房间服务费
                newApartmentUuid: null,//新房间uuid
                newContractUuid: null,//新合同uuid
                noPaidOldApartmentPayBill: null,//所有应付账单集合
                oldApartmentUuid: null,//旧房uuid
                oldContractUuid: null,//旧房合同uuid
                oldElectAmount: null,//应付电费账单总合
                oldWtaterAmount: null,//应付水费账单总额
                payChangeAmount: null,//换房手续费金额
                payChangePayBill: null,//换房手续费账单
                payCleanAmount: null,//清洁费账单金额
                payCleanPayBill: null,//清洁费账单
                payDepoAmount: null,//押金金额
                payElectAmount: null,//应付电费各项金额
                payElectPayBill: null,//应付电费账单
                payMaintianAmount: null,//维修费金额
                payMaintianPayBill: null,//维修费账单
                payOldRentalAmount: null,//应付房租各项金额
                payOldRentalAmountTotal: null,//应付房租总额
                payOldRentalPayBill: null,//应付房租账单
                payOldServiceAmount: null,//应付服务费账单各项金额
                payOldServiceAmountTotal: null,//应付服务费各项金额
                payOldServicePayBill: null,//应付服务费账单
                payOtherAmount: null,//其他费用金额
                payOtherPayBill: null,//其他账单
                payRentalAmount: null,//新房房租账单总额
                payRentalPayBill: null,//新房房租账单
                payServiceAmount: null,//新房服务费账单总额
                payServicePayBill: null,//新房服务费账单
                payWaterAmount: null,//应付水费各项金额
                payWaterPayBill: null,//应付水费账单
                quitRentalAmount: null,//应退房租各项金额
                quitRentalAmountTotal: null,//应退房租账单总金额
                quitRentalPayBill: null,//应退房租账单
                quitServiceAmount: null,//应退服务费账单各项金额
                quitServiceAmountTotal: null,//应退服务费总额
                quitServicePayBill: null,//应退服务费账单
                reantalTotalAmount: null,//房租总合
                totalAmount: null,//总计
                checkOutRent: null,
                checkOutRentUuid: null,
                payElectAmountSplit: null,
                payOldRentalAmountSplit: null,
                payOldServiceAmountSplit: null,
                payWaterAmountSplit: null,
                quitRentalAmountSplit: null,
                quitServiceAmounSplit: null,
                rentalDrawback: null, //房租退款
                expensesDrawback: null, //杂费退款
                rentalPay: null, //房租支付
                expensesPay: null,//杂费付款
                depoPay: null,//押金付款
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            communityUuid: null,
            communityList: [],
            imgList: [],
            cleanShow: false,
            damageShow: false,
            otherShow: false,
            payChangeShow: false,
            oldFangAmountTotal: null,
            newFangAmountTotal: null,
            billTitle: "",
            billSwitch: false,
            billList: [],
            billType: false,
        }
    },
    components: {
        uploadPictureCard: () => import("@/components/Upload/upload-picture-card"),
    },
    async created() {
        this.communityUuid = null;
        //获取所有小区
        let {list} = await getCommunityListApi();
        //过滤没有房间的小区
        list = list.filter(({apartmentsCount}) => apartmentsCount > 0);
        //将小区uuid，name单独拿出
        list = list.map(({name, uuid}) => {
            return {name, uuid}
        });
        //保存小区信息
        this.communityList = list;
    },
    methods: {
        //打开换房弹窗
        openDialog(data) {
            //解构当前房间信息，承租人信息
            const {leasorUuid, leasorName, apartmentUuid: oldApartmentUuid, address} = this.currentHouse;
            //解构换房合同信息
            const {uuid, contractNum} = data;
            //保存旧数据
            const oldHuanFangData = this.huanFangData;
            //封装换房数据
            this.huanFangData = {
                ...oldHuanFangData, leasorUuid, leasorName, oldApartmentUuid, apartmentName: address, contractNum,
                oldContractUuid: uuid
            };
            //保存合同信息
            this.contractsData = data;

            //打开弹窗
            this.dialogVisible = true;
        },

        //换房确认事件
        async handleSubmit() {
            let that = this;
            //解构数据
            const {
                newApartmentUuid, changeDate, leasorUuid, newApartmentDepoAmount, newApartmentRentalAmount,
                newApartmentServiceAmount
            } = that.huanFangData;
            //校验换房时间
            if (changeDate === null || changeDate === "") {
                MessageError("请选择换房时间");
                return;
            }
            //校验新房间号
            if (newApartmentUuid === null || newApartmentUuid === "") {
                MessageError("请选择新房号");
                return;
            }
         let itr =  await  getContracTimeApiworitr({uuid:newApartmentUuid})
          if(itr.info){
            this.$alert('新房间电表抄表失败且无24小时内手动抄表记录，请手动抄表后进行操作!', '注意', {
              confirmButtonText: '确定',
              callback: action => {
              }
            })
            return
          }

            // if(false) return
            //获取新房间名
            let {name} = that.options.find(({uuid}) => uuid === newApartmentUuid);
            //保存新房间名
            that.huanFangData.newApartmentName = name;
            //关闭更换房间弹窗
            this.dialogVisible = false;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading(loadingOptions);

            //获取合同结束时间
            let {endDate} = this.contractsData;
            //转换合同结束时间
            let dt1 = timeFormat(new Date(endDate));
            //转换换房时间
            let dtt = new Date(changeDate);
            //新合同开始时间 换房时间加1天
            let dt = timeFormat(new Date(dtt.getTime() + 8.64e7));

            //封装新房金额，uuid，新合同开始结束时间，租户uuid
            let paramData = {
                bone: ParseIntFormat(newApartmentDepoAmount),
                amount: ParseIntFormat(newApartmentRentalAmount),
                tipAmount: ParseIntFormat(newApartmentServiceAmount),
                apartmentUuid: newApartmentUuid, leasorUuid, dt1, dt
            };

            //调用接口，获取新房预收金额
            setApartmentData(paramData).then(res => {
                //解构金额
                const {info: {bone, displayAmount, tipAmount}} = res;
                //金额转换并赋值
                that.huanFangData.newApartmentDepoAmount = parseFloat((bone / 100).toFixed(2));
                that.huanFangData.newApartmentRentalAmount = parseFloat((displayAmount / 100).toFixed(2));
                that.huanFangData.newApartmentServiceAmount = parseFloat((tipAmount / 100).toFixed(2));
            });

            //调用方法获取应扣换房应扣费用
            await this.getContractsCheckoutData();
            loading.close();
            //打开换房提交弹窗
            this.dialogVisibleXiang = true;
        },
        //换房取消事件
        handleCancel() {
            //情空数据
            this.huanFangData = {
                changeDate: null, reasonCode: 1, reasonName: "房间", comment: null, workPictures: null,
                expenseTotalAmount: null, leasorUuid: null, newApartmentDepoAmount: null, totalAmount: null,
                newApartmentRentalAmount: null, newApartmentServiceAmount: null, newApartmentUuid: null,
                newContractUuid: null, noPaidOldApartmentPayBill: null, oldApartmentUuid: null, oldContractUuid: null,
                oldElectAmount: null, oldWtaterAmount: null, payChangeAmount: null, payChangePayBill: null,
                payCleanAmount: null, payCleanPayBill: null, payDepoAmount: null, payOldServiceAmountTotal: null,
                payElectPayBill: null, payMaintianAmount: null, payMaintianPayBill: null, payOldRentalAmount: null,
                payOldRentalAmountTotal: null, payOldRentalPayBill: null, payOldServiceAmount: null,
                payElectAmount: null, payOldServicePayBill: null, payOtherAmount: null, payOtherPayBill: null,
                payRentalAmount: null, payRentalPayBill: null, payServiceAmount: null, payServicePayBill: null,
                payWaterAmount: null, payWaterPayBill: null, quitRentalAmount: null, quitRentalAmountTotal: null,
                quitRentalPayBill: null, quitServiceAmount: null, quitServiceAmountTotal: null, quitServicePayBill: null,
                reantalTotalAmount: null,
            };
            // 清空下拉框新小区选择
            this.communityUuid = null;
            //调用上传方法，刷新合同列表
            this.$emit('handleSearch');
            //关闭选择新房间弹框
            this.dialogVisible = false;
            //关闭换房提交弹框
            this.dialogVisibleXiang = false;
        },

        //点击换房提交确认事件
        handleXiangSubmit() {
            let that = this;
            const h = this.$createElement;
            that.$confirm(h('div', {style: {color: 'red'}}, '需要租赁部进行审核之后方可进行新房签约'), '注意', {type: 'warning'})
                .then(() => {
                    //保存旧数据
                    let oldHuanFangData = that.huanFangData;
                    //解构金额数据
                    let {
                        expenseTotalAmount, oldElectAmount, oldWtaterAmount, payChangeAmount, payCleanAmount,
                        payDepoAmount, payMaintianAmount, payOldRentalAmountTotal, payOldServiceAmountTotal,
                        payOtherAmount, payRentalAmount, payServiceAmount, quitRentalAmountTotal,
                        quitServiceAmountTotal, reantalTotalAmount, totalAmount, newApartmentDepoAmount,
                        newApartmentRentalAmount, newApartmentServiceAmount, rentalDrawback, expensesDrawback,
                        rentalPay, expensesPay, depoPay
                    } = that.huanFangData;
                    //获取换房图片
                    const imgList = this.imgList;
                    //金额转换
                    expenseTotalAmount = ParseIntFormat(expenseTotalAmount);
                    oldElectAmount = ParseIntFormat(oldElectAmount);
                    oldWtaterAmount = ParseIntFormat(oldWtaterAmount);
                    payChangeAmount = ParseIntFormat(payChangeAmount);
                    payCleanAmount = ParseIntFormat(payCleanAmount);
                    payDepoAmount = ParseIntFormat(payDepoAmount);
                    payMaintianAmount = ParseIntFormat(payMaintianAmount);
                    payOldRentalAmountTotal = ParseIntFormat(payOldRentalAmountTotal);
                    payOldServiceAmountTotal = ParseIntFormat(payOldServiceAmountTotal);
                    payOtherAmount = ParseIntFormat(payOtherAmount);
                    payRentalAmount = ParseIntFormat(payRentalAmount);
                    payServiceAmount = ParseIntFormat(payServiceAmount);
                    quitRentalAmountTotal = ParseIntFormat(quitRentalAmountTotal);
                    quitServiceAmountTotal = ParseIntFormat(quitServiceAmountTotal);
                    reantalTotalAmount = ParseIntFormat(reantalTotalAmount);
                    totalAmount = ParseIntFormat(totalAmount);
                    newApartmentDepoAmount = ParseIntFormat(newApartmentDepoAmount);
                    newApartmentRentalAmount = ParseIntFormat(newApartmentRentalAmount);
                    newApartmentServiceAmount = ParseIntFormat(newApartmentServiceAmount);
                    rentalDrawback = ParseIntFormat(rentalDrawback);
                    expensesDrawback = ParseIntFormat(expensesDrawback);
                    rentalPay = ParseIntFormat(rentalPay);
                    expensesPay = ParseIntFormat(expensesPay);
                    depoPay = ParseIntFormat(depoPay);
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading(loadingOptions);
                    // 封装数据
                    const data = {
                        ...oldHuanFangData, expenseTotalAmount, oldElectAmount, oldWtaterAmount, payChangeAmount,
                        payCleanAmount, payDepoAmount, payMaintianAmount, payOldRentalAmountTotal,
                        payOldServiceAmountTotal, payOtherAmount, payRentalAmount, payServiceAmount,
                        quitRentalAmountTotal, quitServiceAmountTotal, reantalTotalAmount, totalAmount,
                        workPictures: imgList.join(";"), newApartmentDepoAmount, newApartmentRentalAmount,
                        newApartmentServiceAmount, rentalDrawback, expensesDrawback, rentalPay, expensesPay, depoPay
                    }

                    //调用接口提交换房申请
                    renewalRoomChangeSave(data).then(res => {
                        MessageSuccess('换房申请成功');
                        that.handleCancel();
                    }).finally(() => loading.close());
                }).catch(() => {
            });
        },

        //点击上一步按钮事件
        previousStep() {
            //换房提交弹框关闭
            this.dialogVisibleXiang = false;
            //换房选择房间弹框打开
            this.dialogVisible = true;
        },

        //获取应扣换房应扣费用方法
        async getContractsCheckoutData() {
            let that = this;
            // 解构数据
            const {
                newApartmentUuid, changeDate, oldContractUuid, newApartmentDepoAmount, newApartmentRentalAmount,
                newApartmentServiceAmount
            } = that.huanFangData;
            // 转换金额封，装数据
            const data = {
                newApartmentDepoAmount: ParseIntFormat(newApartmentDepoAmount), oldContractUuid,
                newApartmentRentalAmount: ParseIntFormat(newApartmentRentalAmount), changeDate,
                newApartmentServiceAmount: ParseIntFormat(newApartmentServiceAmount), newApartmentUuid,
            }
            // 调用接口获取应扣费用
            let {info} = await getPenewalChangeDetails(data);
            //调用方法处理应扣费用
            this.setData(info);
        },

        //处理应扣费用方法
        setData(data) {
            // 保存旧数据
            const oldHuanFangData = this.huanFangData;
            //解构接口返回数据
            let {
                expenseTotalAmount, newApartmentUuid, noPaidOldApartmentPayBill, oldContractUuid, oldElectAmount,
                oldWtaterAmount, payChangeAmount, payChangePayBill, payCleanAmount, payCleanPayBill, payDepoAmount,
                payElectAmount, payElectPayBill, payMaintianAmount, payMaintianPayBill, payOldRentalAmount,
                payOldRentalAmountTotal, payOldRentalPayBill, payOldServiceAmount, payOldServiceAmountTotal,
                payOldServicePayBill, payOtherAmount, payOtherPayBill, payRentalAmount, payRentalPayBill,
                payServiceAmount, payServicePayBill, payWaterAmount, payWaterPayBill, quitRentalAmountTotal,
                quitRentalAmount, quitRentalPayBill, quitServiceAmount, quitServiceAmountTotal, quitServicePayBill,
                reantalTotalAmount, totalAmount, checkOutRent, checkOutRentUuid, payElectAmountSplit,
                payOldRentalAmountSplit, payOldServiceAmountSplit, payWaterAmountSplit, quitRentalAmountSplit,
                quitServiceAmounSplit, rentalDrawback, expensesDrawback, rentalPay, expensesPay, depoPay
            } = data;
            //金额转换
            expenseTotalAmount = parseFloat((expenseTotalAmount / 100).toFixed(2));
            oldElectAmount = parseFloat((oldElectAmount / 100).toFixed(2));
            oldWtaterAmount = parseFloat((oldWtaterAmount / 100).toFixed(2));
            payChangeAmount = parseFloat((payChangeAmount / 100).toFixed(2));
            payCleanAmount = parseFloat((payCleanAmount / 100).toFixed(2));
            payDepoAmount = parseFloat((payDepoAmount / 100).toFixed(2));
            payMaintianAmount = parseFloat((payMaintianAmount / 100).toFixed(2));
            payOldRentalAmountTotal = parseFloat((payOldRentalAmountTotal / 100).toFixed(2));
            payOldServiceAmountTotal = parseFloat((payOldServiceAmountTotal / 100).toFixed(2));
            payOtherAmount = parseFloat((payOtherAmount / 100).toFixed(2));
            payRentalAmount = parseFloat((payRentalAmount / 100).toFixed(2));
            payServiceAmount = parseFloat((payServiceAmount / 100).toFixed(2));
            quitRentalAmountTotal = parseFloat((quitRentalAmountTotal / 100).toFixed(2));
            quitServiceAmountTotal = parseFloat((quitServiceAmountTotal / 100).toFixed(2));
            reantalTotalAmount = parseFloat((reantalTotalAmount / 100).toFixed(2));
            totalAmount = parseFloat((totalAmount / 100).toFixed(2));
            rentalDrawback = parseFloat((rentalDrawback / 100).toFixed(2));
            expensesDrawback = parseFloat((expensesDrawback / 100).toFixed(2));
            rentalPay = parseFloat((rentalPay / 100).toFixed(2));
            expensesPay = parseFloat((expensesPay / 100).toFixed(2));
            depoPay = parseFloat((depoPay / 100).toFixed(2));

            //封装换房数据
            this.huanFangData = {
                ...oldHuanFangData, expenseTotalAmount, newApartmentUuid, noPaidOldApartmentPayBill, oldContractUuid,
                oldElectAmount, oldWtaterAmount, payChangeAmount, payChangePayBill, payCleanAmount, payCleanPayBill,
                payDepoAmount, payElectAmount, payElectPayBill, payMaintianAmount, payMaintianPayBill,
                payOldRentalAmount, payOldRentalAmountTotal, payOldRentalPayBill, payOldServiceAmount,
                payOldServiceAmountTotal, payOldServicePayBill, payOtherAmount, payOtherPayBill, payRentalAmount,
                payRentalPayBill, payServiceAmount, payServicePayBill, payWaterAmount, payWaterPayBill,
                quitRentalAmountTotal, quitRentalAmount, quitRentalPayBill, quitServiceAmount, quitServiceAmountTotal,
                quitServicePayBill, reantalTotalAmount, totalAmount, checkOutRent, checkOutRentUuid,
                payElectAmountSplit, payOldRentalAmountSplit, payOldServiceAmountSplit, payWaterAmountSplit,
                quitRentalAmountSplit, quitServiceAmounSplit, rentalDrawback, expensesDrawback, rentalPay, expensesPay,
                depoPay
            };

            //调用方法计算费用合计
            this.setTotal(this.huanFangData);
        },

        //计算费用合计方法
        setTotal(data) {
            // 保存旧数据
            let oldHuanFangData = this.huanFangData;
            //解构费用数据
            let {
                expenseTotalAmount, oldElectAmount, oldWtaterAmount, payChangeAmount, payCleanAmount, payDepoAmount,
                payMaintianAmount, payOldRentalAmountTotal, payOldServiceAmountTotal, payOtherAmount, payRentalAmount,
                payServiceAmount, quitRentalAmountTotal, quitServiceAmountTotal, reantalTotalAmount, totalAmount
            } = data;
            //金额转换
            expenseTotalAmount = parseFloat(expenseTotalAmount);
            oldElectAmount = parseFloat(oldElectAmount);
            oldWtaterAmount = parseFloat(oldWtaterAmount);
            payChangeAmount = parseFloat(payChangeAmount);
            payCleanAmount = parseFloat(payCleanAmount);
            payDepoAmount = parseFloat(payDepoAmount);
            payMaintianAmount = parseFloat(payMaintianAmount);
            payOldRentalAmountTotal = parseFloat(payOldRentalAmountTotal);
            payOldServiceAmountTotal = parseFloat(payOldServiceAmountTotal);
            payOtherAmount = parseFloat(payOtherAmount);
            payRentalAmount = parseFloat(payRentalAmount);
            payServiceAmount = parseFloat(payServiceAmount);
            quitRentalAmountTotal = parseFloat(quitRentalAmountTotal);
            quitServiceAmountTotal = parseFloat(quitServiceAmountTotal);
            reantalTotalAmount = parseFloat(reantalTotalAmount);
            totalAmount = parseFloat((totalAmount).toFixed(2));
            //旧房费用合计
            const oldFangAmountTotal = payOldRentalAmountTotal + payOldServiceAmountTotal + oldWtaterAmount +
                oldElectAmount + payCleanAmount + payMaintianAmount + payOtherAmount + payChangeAmount;
            //新房费用合计
            const newFangAmountTotal = payDepoAmount + payRentalAmount + payServiceAmount;
            reantalTotalAmount = (payOldRentalAmountTotal + payRentalAmount + payDepoAmount) - quitRentalAmountTotal;
            expenseTotalAmount = (payOldServiceAmountTotal + oldWtaterAmount + oldElectAmount + payCleanAmount +
                payMaintianAmount + payOtherAmount + payChangeAmount + payServiceAmount) - quitServiceAmountTotal;
            totalAmount = reantalTotalAmount + expenseTotalAmount;

            //封装换房数据
            this.huanFangData = {
                ...oldHuanFangData, expenseTotalAmount, oldElectAmount, oldWtaterAmount, payChangeAmount,
                payCleanAmount, payDepoAmount, payMaintianAmount, payOldRentalAmountTotal, payOldServiceAmountTotal,
                payOtherAmount, payRentalAmount, payServiceAmount, quitRentalAmountTotal, quitServiceAmountTotal,
                reantalTotalAmount, totalAmount,
            };
            //赋值新房合计费用
            this.newFangAmountTotal = newFangAmountTotal;
            //赋值旧房合计费用
            this.oldFangAmountTotal = oldFangAmountTotal;
        },

        //根据小区获取换房房间放
        async renewalByName(communityUuid) {
            //调用接口获取小区下所有未出租房间
            let {list} = await renewalByName({communityUuid, status: 1});
            this.options = list;
        },

        //获取新房间信息方法
        async getApartmentData(data) {
            //调用接口获取新房间信息
            return await getApartmentData(data);
        },

        //点击编辑金额
        amountClick(name, ref) {
            //显示组件
            this[name] = true;
            //组件渲染完成后光标选中
            this.$nextTick(() => this.$refs[ref].focus());
        },

        //输入框光标移除事件
        amountBlur(name, ref) {
            //隐藏组件
            this[name] = false;
            //金额格式化
            this.huanFangData[ref] = (parseFloat(this.huanFangData[ref])).toFixed(2);
            //调用方法计算费用合计
            this.setTotal(this.huanFangData);
        },

        //格式化金额方法
        numberFormat(number = 0) {
            number = parseFloat(number)
            return numberFormat(number);
        },

        //格式化日期方法
        timeFormat(timedate, format = "yyyy-MM-dd") {
            if (timedate) return timeFormat(new Date(timedate), format);
            else return "";
        },

        //换房图片上传成功事件
        handleSuccess({fileList}) {
            //获取全部上传成功图片uuid
            this.imgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
        },

        //退房图片删除成功回调
        handleRemove({fileList}) {
            //获取全部剩余图片uuid
            this.imgList = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
        },

        //获取账单列表
        goBill(title, data, type = false) {
            if (data.length > 0) {
                let that = this;
                that.billTitle = title;
                that.billType = type;
                //获取账单列表
                that.billList = data;
                //打开账单弹窗
                that.billSwitch = true;
            } else MessageInfo('无' + title + '账单');
        },

        //账单列表弹窗关闭
        billListClose() {
            this.billTitle = null;
            this.billList = null;
            this.billSwitch = false;
        },

        //账单列表合计
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) return prev + curr;
                        else return prev;
                    }, 0);
                    sums[index] = numberFormat(sums[index] / 100);
                } else sums[index] = '';

            });
            return sums;
        }
    },
    watch: {
        async 'huanFangData.newApartmentUuid'(value) {
            if (value) {
                let {dt} = this.huanFangData;
                dt = dt || timeFormat(new Date());
                let {info} = await this.getApartmentData({uuid: value, dt});
                let {bone, displayAmount, tipAmount} = info;
                this.huanFangData.newApartmentDepoAmount = parseFloat((bone / 100).toFixed(2));
                this.huanFangData.newApartmentRentalAmount = parseFloat((displayAmount / 100).toFixed(2));
                this.huanFangData.newApartmentServiceAmount = parseFloat((tipAmount / 100).toFixed(2));
            }
        },
        async communityUuid(value) {
            if (value) {
                this.options = [];
                this.renewalByName(value);
            } else this.options = [];
        },
        'huanFangData.reasonCode'(value) {
            if (value === 1) {
                this.huanFangData.reasonName = "房间";
                this.huanFangData.payChangeAmount = 0;
            }
            if (value === 2) this.huanFangData.reasonName = "客户";
        }
    }

}
</script>

<style lang="scss" scoped>
.el-select {
    width: 100%;
}

.title {
    position: relative;
    padding-left: 10px;

    &::before {
        width: 5px;
        height: 100%;
        content: '';
        background-color: #F5A623;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.flex {
    display: flex;

    /deep/ .el-form-item {
        flex: 1;
        white-space: nowrap;
    }

    /deep/ .amount {
        .el-input {
            width: 42%;
        }
    }

    /deep/ .tag-group {
        white-space: normal;

        .el-tag {
            margin: 0 VW(5px) VH(5px) 0;
            cursor: pointer;
        }
    }

    /deep/ .samplePhotos {
        white-space: normal;
    }
}

.row {
    margin-bottom: 10px;
    padding: 10px 20px;
    border: 1px solid #D7D7D7;
}

.width300 {
    width: 300px;
}

.width100 {
    width: 100px;
}

.boder-right {
    border-right: 1px solid #D7D7D7;
}

.bg-E6E6E6 {
    background-color: #E6E6E6;
}

.bg-E2F5FF {
    background-color: #E2F5FF;
}

.bg-F8F8F8 {
    background-color: #F8F8F8;
}

.width33b {
    width: 25%;
}

.padding_0_30 {
    padding: 0 30px
}

.padding_10_30 {
    padding: 10px 30px;
}

.fw600 {
    font-weight: 600;
}
</style>
<style scoped>
.v-modal {
    z-index: 2000 !important;
}
</style>
